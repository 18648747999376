<template>
  <b-form-group :label="label">
    <template v-if="label" v-slot:label>
      {{ label }}
      <span v-if="required" class="text-danger"> (*) </span>
    </template>
    <v-select
      id="status"
      v-model="option"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="options"
      placeholder="Trạng thái liên kết"
      @input="valueChanged"
      ><!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Không có dữ liệu
      </template></v-select
    >
  </b-form-group>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      option: this.defaultValue,
      options: [
        { title: "Chưa liên kết", id: false, value: false },
        { title: "Đã liên kết", id: true, value: true },
      ],
    };
  },
  methods: {
    valueChanged() {
      this.$emit("input", this.option);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/vue-select.scss";
</style>
