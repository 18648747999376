<template>
  <div class="student-list-container">
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ t("Học sinh") }}</b-card-title>
        <crud-buttons
          :name="t('Học sinh')"
          :selected-rows="selectedRows"
          modal="modal-student"
          :show-import="$can('create', 'student')"
          :show-export="true"
          :hide-delete="!$can('delete', 'student')"
          :enable-add="$can('create', 'student')"
          :link-template-file="
            provider === 'vbill'
              ? 'https://cdn.resident.vn/attachments/1733970188805_mau-nhap-lieu-khach-hang.xlsx'
              : 'https://cdn.resident.vn/attachments/1703816048273_mau-nhap-lieu-hoc-sinh.xlsx'
          "
          @on-add="resetItem"
          @on-delete="onDelete"
          @on-export="exportData"
          @on-import-file="importData"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <select-school v-model="school" :label="null" />
          </b-col>
          <b-col>
            <select-class v-model="classObject" :school="school" />
          </b-col>
          <b-col>
            <select-linked-zalo-status v-model="isLinked" />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner label="Loading" type="grow" />
              </template>
              <template slot="table-row" slot-scope="props">
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-student-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <span v-else-if="props.column.field === 'linkedOA'">
                  <b-link
                    v-if="props.row.zaloContacts.length > 0"
                    @click="onEditItem(props.row)"
                    v-b-modal.modal-zalo-account
                  >
                    <b-badge pill class="size-18" :variant="`light-success`">{{
                      `${props.row.zaloContacts.length} tài khoản`
                    }}</b-badge>
                  </b-link>
                  <b-badge
                    v-else
                    pill
                    class="size-18"
                    :variant="`light-danger`"
                    >{{ "KH chưa liên kết" }}</b-badge
                  >
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    :name="t('Học sinh')"
                    modal="modal-student"
                    :hide-delete="!$can('delete', 'student')"
                    :hide-edit="!$can('update', 'student')"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  />
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="[
                        '10',
                        '20',
                        '50',
                        '100',
                        '500',
                        '1000',
                        '2000',
                      ]"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">Không có bản ghi nào!</div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <student-modal :item="item" @refetch-data="fetchStudents" />
    <student-detail-modal :student-id="item.id" />
    <zalo-account-modal :item="item" @refetch-data="fetchStudents" />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import TableActions from "@/views/components/TableActions.vue";
import CrudButtons from "@/views/components/CrudButtons.vue";
// eslint-disable-next-line import/no-cycle
import useStudentList from "./useStudentList";
import StudentModal from "../modal/StudentModal.vue";
import StudentDetailModal from "../detail/StudentDetailModal.vue";
import SelectSchool from "../../components/SelectSchool.vue";
import SelectClass from "../../components/SelectClass.vue";
import ZaloAccountModal from "../zalo-account/ZaloAccountModal.vue";
import SelectLinkedZaloStatus from "@/views/components/SelectLinkedZaloStatus.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    VueGoodTable,
    TableActions,
    CrudButtons,
    StudentModal,
    StudentDetailModal,
    SelectSchool,
    SelectClass,
    ZaloAccountModal,
    SelectLinkedZaloStatus,
  },
  setup() {
    const {
      item,
      columns,
      rows,
      school,
      classObject,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchStudents,
      deleteStudents,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      importData,
      exportData,
      downloadImportTemplate,
      isLinked,
      t,
    } = useStudentList();

    const provider = process.env.VUE_APP_PROVIDER;

    return {
      item,
      columns,
      rows,
      school,
      classObject,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,
      isLinked,
      fetchStudents,
      deleteStudents,
      resetItem,
      onEditItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,
      importData,
      exportData,
      downloadImportTemplate,
      t,
      provider,
    };
  },

  created() {
    this.fetchStudents();
  },

  methods: {
    onDelete(student) {
      const deleteObjects =
        student && student.id > 0 ? [student] : this.selectedRows;
      this.deleteStudents(deleteObjects);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }

    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}

.student-list-container {
  .vgt-table {
    thead {
      tr {
        th {
          min-width: 100px !important;

          &:first-child {
            min-width: 50px !important;
          }

          &:nth-child(2) {
            min-width: 60px !important;
          }

          &:nth-child(3) {
            min-width: 80px !important;
          }

          &:nth-child(4) {
            min-width: 120px !important;
          }

          &:nth-child(6) {
            min-width: 200px !important;
          }

          &:nth-child(7) {
            min-width: 200px !important;
          }
        }
      }
    }
  }
}
</style>
