import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import studentStoreModule from '../studentStoreModule';
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default function useStudentList() {
  const STORE_MODULE_NAME = 'student';
  const { t } = useI18nUtils();
  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, studentStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    name: '',
    refId: '',
    contactPhone: '',
    contactEmail: '',
    note: '',
    fatherName: '',
    motherName: '',
    active: true,
    classTime: '',
    tableTime: '',
    tuitionFee: 0,
    hourPerDay: 0,
    startDate: null,

  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'student') && !vm.$can('delete', 'student');

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
      width: '80px'
    },
    {
      label: 'Thao tác',
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: t('Cơ sở'),
      field: 'school.name',
    },
    {
      label: t('Lớp học'),
      field: 'class.name',
    },
    {
      label: t('Học sinh'),
      field: 'name',
    }, {
      label: 'SĐT liên hệ',
      field: 'contactPhone',
    },
    {
      label: 'Số tk Zalo nhận thông báo',
      field: 'linkedOA',
    }

  ];
  const rows = ref([]);
  // filter
  const school = ref(null);
  const classObject = ref(null);

  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const isLinked = ref(null)
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {
      type: 'asc',
      field: 'firstName',
    },
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchStudents = () => {
    isLoading.value = true;
    store
      .dispatch('student/fetchStudents', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteStudents = students => {
    store
      .dispatch('student/deleteStudents', {
        ids: students.map(_obj => _obj.id),
      })
      .then(() => {
        fetchStudents();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const downloadImportTemplate = () => {
    store
      .dispatch('student/generateImportStudentTemplate')
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('student/importStudents', formData)
      .then(() => {
        fetchStudents();
        toastification.showToastCreateSuccess();
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('student/exportStudents', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchStudents();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  //   Watch

  watch(school, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.schoolId = val.id;
    } else {
      delete filter.schoolId;
    }
    updateParams({ filter });
  });
  watch(classObject, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.classId = val.id;
    } else {
      delete filter.classId;
    }
    updateParams({ filter });
  });
  watch(isLinked, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.isLinkedZalo = val.id;
    } else {
      delete filter.isLinkedZalo;
    }
    updateParams({ filter });
  });


  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  return {
    item,
    columns,
    rows,
    school,
    classObject,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    isLinked,

    fetchStudents,
    deleteStudents,
    resetItem,
    onEditItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
    downloadImportTemplate,
    t
  };
}
